import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import SingleImage from '../library/SingleImage';
import ContactBar from '../components/ContactBar';
import BookNow from '../components/BookNow';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const AboutPage = (props) => (
  <Layout location={props.location}>
    <div id="about" className="page">
      <Helmet>
        <title>Porträt, über meinen Werdegang</title>
        <meta name="description" content="Porträt: 2013 Diplom zur Naturheilpraktikerin TCM für Akupunktur, Kräutermedizin, Ernährung, Qi gong" />
        <meta property="og:title" content="Porträt, über meinen Werdegang" />
        <meta property="og:description" content="Porträt: 2013 Diplom zur Naturheilpraktikerin TCM für Akupunktur, Kräutermedizin, Ernährung, Qi gong" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container" >
        <h1>Porträt</h1>
        <SingleImage title="Paola Nakahara" caption="Dipl. Naturheilpraktikerin TCM" image={props.data.profileImage.fluid} />
        <div className="column">
          <p>Ich bin 1980 geboren und habe nach meiner Schulausbildung den Weg in den Gesundheitsbereich gewählt. Die Vorgänge im menschlichen Körper, das Verständnis von Gesundheit und Krankheit und die Erhaltung einer natürlichen, starken Konstitution haben mich schon immer interessiert. Als Pflegefachfrau bin ich des Öfteren an die Grenzen der Schulmedizin gestossen und habe gesehen, dass nur Symptome lindern kein Weg zur dauerhaften Gesundung darstellen kann.</p>
          <p>Die Chinesische Medizin bietet darin viel mehr Möglichkeiten. Die Sicht der Dinge ist nicht nur auf das Symptome und seine unmittelbare Ursache beschränkt, sondern bezieht Geist, Körper, Seele, Soziales und Vergangenes mit ein. Der Mensch wird als Ganzes betrachtet und behandelt. Dieser ganzheitliche Aspekt fasziniert mich an der TCM. Es ist eine neue und etwas andere Art zu denken und zu handeln, die sich mit unseren hiesigen Lebensumständen aber gut vereinbaren lässt.</p>
          <h2>Weiterbildungen</h2>
          <table>
            <tbody>
              <tr>
                <td>2022</td>
                <td>System der klassischen Rezepturen bei Andreas Kalg</td>
              </tr>
              <tr>
                <td>2019/2020</td>
                <td>Gynäkologie und integrative Kinderwunschbehandlung mit TCM</td>
              </tr>
              <tr>
                <td>Juni 2018</td>
                <td>Dr. Tan's Balance Methode Teil II</td>
              </tr>
              <tr>
                <td>Januar 2018</td>
                <td>Funktionelle Leitbahndiagnostik zur Behandlung von Störungen im Bewegungsapparat bei Birgit Seybold-Kellner</td>
              </tr>
              <tr>
                <td>Oktober 2016</td>
                <td>Bewegungsapparatstörungen bei Jim Skoien</td>
              </tr>
              <tr>
                <td>Juni 2016</td>
                <td>Dr. Tan`s Balance Method</td>
              </tr>
              <tr>
                <td>April 2015</td>
                <td>Kinesio Tape bei Rolf Leuenberger</td>
              </tr>
              <tr>
                <td>Oktober 2014</td>
                <td>Pain Treatment bei Jeffrey Yuen</td>
              </tr>
              <tr>
                <td>Mai 2014</td>
                <td>TCM Dermatologie bei Dr. Tian Li</td>
              </tr>
              <tr>
                <td>April 2014</td>
                <td>Gastrointestinale Erkrankungen bei Jürg Wilhelm</td>
              </tr>
              <tr>
                <td>Februar 2014</td>
                <td>TCM Schwangerschaft bei Ruthild Schulze</td>
              </tr>
            </tbody>
          </table>
          <h2>Lebenslauf</h2>
          <h3>2016</h3>
          <p>Geburt meines Sohnes</p>
          <h3>Ab 2013</h3>
          <p>eigene Praxistätigkeit in Zürich Oerlikon</p>
          <h3>2013</h3>
          <p>Diplom zur Naturheilpraktikerin TCM für Akupunktur, Kräutermedizin, Tuina (Massage), Ernährung, Qi gong</p>
          <h3>2011 – 2013</h3>
          <p>Diverse Praktika: 2011 – 2013 in Zürich, 2011 National Hospital of Traditional Medicine in Hanoi/Vietnam, 2013 Yo San University of Traditional Chinese Medicine in Los Angeles</p>
          <h3>2009 – 2013</h3>
          <p>Studium der Traditionell Chinesischen Medizin (TCM) an der TAO CHI Schule Zürich unter Jim Skoien</p>
          <h3>2001 bis 2016</h3>
          <p>tätig als Pflegefachfrau in verschiedenen Spitälern in Dresden und Zürich</p>
          <h3>2007</h3>
          <p>Umzug nach Zürich</p>
          <h3>1998-2001</h3>
          <p>Ausbildung zur diplomierten Pflegefachfrau in Dresden</p>
        </div>
      </div>
      <BookNow />
      <Contact />
      <Footer />
    </div>
  </Layout>
)

export default AboutPage;

export const query = graphql`
  query AboutPageImageQuery {
    profileImage: imageSharp(fluid: { originalName: {regex: "/paola_nakahara/" }}) {
      fluid(maxWidth: 1024 ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
